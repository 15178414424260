<template>
  <b-card
      no-body
      :class="{'open': visible}"
      @mouseenter="collapseOpen"
      @mouseleave="collapseClose"
  >
    <b-card-header
        :class="{'collapsed': !visible}"
        :aria-expanded="visible ? 'true' : 'false'"
        :aria-controls="collapseItemID"
        role="tab"
        data-toggle="collapse"
        @click="updateVisible(!visible)"
    >
      <slot v-if="!vardiya" name="header">
        <span class="lead collapse-title">{{ title }}</span>
      </slot>
      <slot v-else name="header">
        <span class="lead collapse-title">{{ title }}
        <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            text="Primary"
            dropleft
            variant="flat-primary"
            no-caret
        >
          <template #button-content>
            <font-awesome-icon icon="bars"/>
          </template>
          <b-dropdown-item variant="info" :to="`/vardiyaDetayi/${vardiya.vardiyaID}`">
            Vardiya Detayı
          </b-dropdown-item>
          <b-dropdown-item variant="success" :to="`/detayliTablo/${vardiya.vardiyaID}`">
            Detaylı Gelir Listesi
          </b-dropdown-item>
          <b-dropdown-item variant="warning" :to="`/giderListesi/${vardiya.vardiyaID}`">
            Gider Listesi
          </b-dropdown-item>
          <b-dropdown-item variant="primary" :to="`/adisyonListesi/${vardiya.vardiyaID}`">
            Adisyon Listesi
          </b-dropdown-item>
        </b-dropdown>
        </span>
        <small class="font-weight-bolder">( {{ vardiya.baslangic.slice(0, 16) }} /
          {{ vardiya.bitis.slice(0, 16) }} )</small>
      </slot>
    </b-card-header>
    <b-collapse
        :id="collapseItemID"
        v-model="visible"
        :accordion="accordion"
        role="tabpanel"
    >
      <b-card-body>
        <slot/>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  directives: {
    Ripple,
  },
  props: {
    vardiya: {
      type: Object,
      default: Object,
      result: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>

<style scoped>
.dropdown .btn {
  padding: 0.786rem 1.2rem;
}

</style>
