<template>
  <div>
    <b-modal
        id="payment-modal"
        centered
        no-fade
        hide-footer
        size="xl"
        :title="`Masa Adı : ${masaAdi}`"
    >
      <b-row>
        <b-col xl="4" lg="6" md="12" sm="12" cols="12" class="order-xl-1 order-lg-3 order-md-3 order-sm-3 order-3">
          <b-list-group flush class="mt-1">
            <b-list-group-item v-for="(item,i) in orderData.urunList" :key="item.detayID + i" class="urun-list">
              <b-row>cls
                <b-col cols="12" class="d-flex justify-content-between">
                  <div>
                    <small>{{ item.adet }}x</small>
                    <strong class="ml-50">{{ item.stokAdi }}</strong>
                  </div>
                  <div>
                    <strong>
                      {{
                        Number(item.tutar)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }}
                      <font-awesome-icon icon="turkish-lira-sign"/>
                    </strong>
                  </div>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between toplam-tutar-list pull-up">
              <div>
                <strong>Toplam Tutar : </strong>
              </div>
              <div>
                <strong>{{
                    Number(orderData.finans.toplamTutar)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                  }}</strong>
                <font-awesome-icon icon="turkish-lira-sign"/>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col xl="4" lg="6" md="12" sm="12" cols="12" class="order-xl-2 order-lg-2 order-md-2 order-sm-2 order-2">
          <b-row class="odeme-tip-row mt-lg-0 mt-md-2 mt-sm-2 mt-2">
            <b-col lg="6" md="6" sm="6" cols="6" class="p-25">
              <b-card v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :class="odemeTipSelected === 'nakit' ? 'tip-selected':''"
                      @click="odemeTipSelected = 'nakit'"
              >
                <div class="odeme-tipi">
                  <font-awesome-icon icon="money-bills" class="fa-2xl"/>
                  <h5 class="mt-50">Nakit</h5>
                </div>
              </b-card>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="6" class="p-25">
              <b-card v-ripple.400="'rgba(113, 102, 240, 0.15)'" :class="odemeTipSelected === 'kredi'?'tip-selected':''"
                      @click="odemeTipSelected = 'kredi'"
              >
                <div class="odeme-tipi">
                  <font-awesome-icon icon="credit-card" class="fa-2xl"/>
                  <h5 class="mt-50">Kredi Kartı</h5>
                </div>
              </b-card>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="6" class="p-25">
              <b-card v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :class="odemeTipSelected === 'havale'?'tip-selected':''"
                      @click="odemeTipSelected = 'havale'"
              >
                <div class="odeme-tipi">
                  <font-awesome-icon icon="money-bill-transfer" class="fa-2xl"/>
                  <h5 class="mt-50">Havale</h5>
                </div>
              </b-card>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="6" class="p-25">
              <b-card v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :class="odemeTipSelected === 'acik-hesap'?'tip-selected':''"
                      @click="odemeTipSelected = 'acik-hesap' , $bvModal.show('cari-list') , cariSearch()"
              >
                <div class="odeme-tipi">
                  <font-awesome-icon icon="users" class="fa-2xl"/>
                  <h5 class="mt-50">Açık Hesap</h5>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="odemeTipSelected === 'acik-hesap' && cariKart" class="mt-2">
            <b-col cols="12">
              <b-list-group>
                <b-list-group-item class="musteri-list">
                  <strong>Müşteri Adı : {{
                      musteriAdi.length !== 1 ? musteriAdi : `${cariKart.adi} ${cariKart.soyAdi}`
                    }}</strong>
                  <b-button
                      variant="gradient-danger"
                      class="btn-icon rounded-circle"
                      @click="cariKart = null"
                  >
                    <font-awesome-icon icon="trash"/>
                  </b-button>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col lg="6" md="6" sm="12" cols="12">
              <b-form-group
                  label="Tutar"
                  label-for="basicInput"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <font-awesome-icon icon="turkish-lira-sign"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="basicInput"
                      ref="tutar-input"
                      v-model="tutar"
                      v-numericOnly
                      class="tutar-input"
                      type="number"
                      autocomplete="off"
                      @keyup.enter="odemeSave(1)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        icon="XIcon"
                        class="cursor-pointer text-danger"
                        @click="tutar = '',$refs['tutar-input'].focus()"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" cols="12">
              <b-form-group
                  label="Fiş No"
                  label-for="basicInput"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <font-awesome-icon icon="hashtag"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="basicInput"
                      v-model="fisNo"
                      autocomplete="off"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Not"
                  label-for="basicInput"
              >
                <b-form-textarea
                    id="textarea-default"
                    v-model="aciklama"
                    rows="3"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button :disabled="btnDisabled()" variant="relief-success" class="mr-50 ode-btn-color"
                        @click="odemeSave(1)"
              >
                <font-awesome-icon icon="floppy-disk" class="mr-50"/>
                Öde
              </b-button>
              <b-button :disabled="btnDisabled()" variant="relief-success" @click="odemeSave(2)">
                <font-awesome-icon icon="floppy-disk" class="mr-50"/>
                Öde ve Kapat
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="4" lg="6" md="12" sm="12" cols="12" class="order-xl-3 order-lg-1 order-md-1 order-sm-1 order-1">
          <h4 class="mt-md-1">Tahsilat Listesi</h4>
          <hr>
          <b-badge v-if="orderData.finans.tahsilatList.length === 0" variant="primary" class="mb-2">
            Tahsilat Yok
          </b-badge>
          <b-list-group>
            <b-list-group-item v-for="(item,i) in orderData.finans.tahsilatList" :key="item.kasaID+i"
                               class="d-flex justify-content-between"
            >
              <div class="tah-list-tur">
                <span class="mr-1">
                    <font-awesome-icon v-if="item.tahsilatTuru === '1'" icon="money-bills"/>
                    <font-awesome-icon v-if="item.tahsilatTuru === '2'" icon="credit-card"/>
                    <font-awesome-icon v-if="item.tahsilatTuru === '3'" icon="money-bill-transfer"/>
                    <font-awesome-icon v-if="item.tahsilatTuru === '4'" icon="user"/>
                </span>
                <span>{{ tahTuru(item.tahsilatTuru) }}</span>
              </div>
              <p v-if="item.aciklama" class="d-flex place-center mb-0 px-1">{{ item.aciklama }}</p>
              <div class="text-right">
                <strong class="mr-1">{{
                    Number(item.tutar)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                  }}
                  <font-awesome-icon icon="turkish-lira-sign"/>
                </strong>
                <b-button
                    v-if="!item.vardiyaID"
                    variant="gradient-danger"
                    class="btn-icon rounded-circle"
                    @click="tahsilatRemove(item.tahsilatID)"
                >
                  <font-awesome-icon icon="trash"/>
                </b-button>
              </div>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between tahsil-edilen-list pull-up">
              <div>
                <span class="mr-1">
                    <font-awesome-icon icon="cash-register"/>
                </span>
                <strong>Tahsil Edilen : </strong>
              </div>
              <strong>{{
                  Number(orderData.finans.toplamTahsilat)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }}
                <font-awesome-icon icon="turkish-lira-sign"/>
              </strong>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between kalan-borc-list pull-up">
              <div>
                <span class="mr-1">
                    <font-awesome-icon icon="cash-register"/>
                </span>
                <strong>Kalan Borç : </strong>
              </div>
              <strong>{{
                  Number(orderData.finans.kalanBorc)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }}
                <font-awesome-icon icon="turkish-lira-sign"/>
              </strong>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="cari-list"
        ok-title="Kaydet"
        cancel-title="Kapat"
        centered
        no-fade
        no-close-on-backdrop
        title="Cari Seçimi"
        modal-class="modal-warning"
    >
      <v-select
          v-model="cariKart"
          :options="cariList"
          :get-option-label="option => option.ad + ' ' + option.soyad + ' - ' + option.faturaUnvan"
          input-id="invoice-data-client"
          :clearable="false"
          @search="(search, loading) => { loading(true)
                         cariSearch(search).then(() => loading(false))
                        }"
      >
        <template #no-options>
          <p>Müşteri Bulunamadı</p>
        </template>
        <template #selected-option-container="{ option }">
          <div class="vs__selected">
            {{
              getCariOptionFormat(option.faturaUnvan, option.adi, option.soyAdi).length !== 1 ? getCariOptionFormat(option.faturaUnvan, option.adi, option.soyAdi) : `${option.adi} ${option.soyAdi}`
            }}
          </div>
        </template>
        <template #option="{ faturaUnvan, adi,soyAdi}">
          {{
            getCariOptionFormat(faturaUnvan, adi, soyAdi).length !== 1 ? getCariOptionFormat(faturaUnvan, adi, soyAdi) : `${adi} ${soyAdi}`
          }}
        </template>
        <template #list-header>
          <li
              v-b-modal.new-cari
              class="add-new-client-header d-flex align-items-center my-50"
          >
            <feather-icon
                icon="PlusIcon"
                size="16"
            />
            <span class="align-middle ml-25">Yeni Cari Kart</span>
          </li>
        </template>
      </v-select>
    </b-modal>
    <b-modal
        id="new-cari"
        ok-title="Kaydet"
        cancel-title="Kapat"
        centered
        no-fade
        hide-footer
        size="xl"
        title="Yeni Cari"
    >
      <b-row class="px-3 mt-1">
        <b-col cols="12">
          <cari-save @saving="cariSave"/>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BFormTextarea,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  VBModal,
  BvModal,
  VBTooltip,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import axios from 'axios'
import CariSave from '@/views/cari/cariSave.vue'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  components: {
    BInputGroupAppend,
    BBadge,
    BFormTextarea,
    CariSave,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  props: ['orderData', 'adisyonID', 'masaAdi'],
  data() {
    return {
      aciklama: null,
      fisNo: null,
      cariKart: null,
      tutar: null,
      odemeTipSelected: 'nakit',
    }
  },
  computed: {
    tahsilatTuru() {
      const tip = this.odemeTipSelected
      return tip === 'nakit' ? 1 : tip === 'havale' ? 2 : tip === 'kredi' ? 3 : tip === 'acik-hesap' ? 4 : 1
    },
    musteriAdi() {
      return this.cariKart.faturaUnvan ? this.cariKart.faturaUnvan.toUpperCase() : `${this.cariKart.adi} ${this.cariKart.soyAdi}`
    },
    cariList: () => store.getters.GET_CARI_LIST,
  },
  created() {
    this.tutar = this.orderData.finans.kalanBorc
    if (this.adisyonID) {
      this.$bvModal.show('payment-modal')
    }
  },
  methods: {
    tahsilatRemove(tahID) {
      const fd = this.postSchema()
      fd.append('methodName', 'tahsilatSil')
      fd.append('tahsilatID', tahID)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.$emit('finished', 1, 0)
              this.tutar = response.adisyonData.finans.kalanBorc
              var input = document.querySelector('.tutar-input')
              input.classList.remove('tutar-input')
              void input.offsetWidth
              input.classList.add('tutar-input')
            }
          })
    },
    odemeSave(a) {
      const fd = this.postSchema()
      fd.append('methodName', 'tahsilatKayit')
      fd.append('tutar', String(this.tutar)
          .replace(/,/g, '.') || 0)
      fd.append('tahsilatTuru', this.tahsilatTuru || 1)
      fd.append('aciklama', this.aciklama || '')
      fd.append('fisNo', this.fisNo || '')
      if (this.odemeTipSelected === 'acik-hesap') {
        fd.append('cariKartID', this.cariKart.cariKartID || '')
      }
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              if (a === 1) {
                this.$emit('finished', 1, 1)
              } else if (a === 2) {
                if (response.adisyonData.finans.kalanBorc === 0) {
                  this.orderClosed()
                } else {
                  this.$emit('finished', 1, 1)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Adisyon Kapatılamadı',
                      text: 'Adisyonun toplam tutarı ödenmediği için, adisyon kapatılamadı',
                      icon: 'ThumbsDownIcon',
                      variant: 'warning',
                    },
                  })
                }
              }
            }
          })
    },
    orderClosed() {
      const fd = this.postSchema()
      fd.set('serviceName', 'adisyon')
      fd.append('methodName', 'adisyonKapat')
      fd.append('adisyonID', this.adisyonID)
      axios.post('', fd)
          .then(response => (response.result.status === 200 && this.$route.name !== 'dashBoard' ? router.push('/') : this.$emit('finished', 1, 1)))
    },
    btnDisabled() {
      if (this.tutar === null || this.tutar === '') {
        return true
      }
      if (this.odemeTipSelected === 'acik-hesap' && (this.cariKart === null || this.cariKart === '')) {
        return true
      }
      return false
    },
    getCariOptionFormat(faturaUnvan, adi, soyAdi) {
      if (faturaUnvan !== '') {
        return faturaUnvan.toUpperCase()
      } else {
        return `${adi} ${soyAdi}`
      }
    },
    cariSave(value, cari) {
      this.cariKart = cari
      this.$bvModal.hide('new-cari')
    },
    cariSearch(searchKey) {
      return new Promise((resolve, reject) => {
        const fd = this.postSchema()
        fd.set('serviceName', 'cari')
        fd.append('methodName', 'cariListeSearch')
        fd.append('searchKey', searchKey || '')
        fd.append('baslangic', 0)
        fd.append('uzunluk', 20)
        axios.post('', fd)
            .then(response => {
              resolve(response.cariList)
              store.commit({
                type: 'SET_CARI_LIST',
                list: response.cariList,
                action: 1,
              })
            })
            .catch(() => reject(false))
      })
    },
    tahTuru: tur => (tur === '1' ? 'Nakit' : tur === '2' ? 'havale' : tur === '3' ? 'Kredi Kartı' : tur === '4' ? 'Açık Hesap' : 'Nakit'),
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'adisyon')
      fd.append('adisyonID', this.adisyonID !== 0 ? this.adisyonID : '')
      return fd
    },
  },
}
</script>

<style scoped>
.toplam-tutar-list {
  background-color: #B3FFAE !important;
}

.urun-list {
  background-color: #F7EDDB !important;
}

.odeme-tipi {
  display: grid;
  place-items: center;
}

.tip-selected {
  border: 2px solid #4CACBC;
}

.odeme-tip-row .card {
  margin: 0;
}

.odeme-tipi .card-body {
  padding: 0.8rem;
}

.odeme-tip-row .card-body {
  padding: 20px 0 5px 0;
}

.musteri-list {
  border: 1px solid #6fbb83 !important;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.tahsil-edilen-list {
  background-color: #9affde !important;
}

.kalan-borc-list {
  background-color: #d0fde2 !important;
}

.tah-list-tur {
  place-items: center;
  display: flex;
}

@keyframes change-bg {
  0% {
    background-color: transparent;
  }
  99.9% {
    background-color: #B3FFAE;
  }
  100% {
    background-color: transparent;
  }
}

.tutar-input {
  animation: change-bg 500ms;
}

.place-center {
  place-items: center;
}

.ode-btn-color {
  background-color: #0dab76;
}

</style>
