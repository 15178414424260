<template>
  <div>
    <b-card-actions
        v-if="adisyon"
        ref="siparis-yonetimi"
        class="siparis-yonetimi"
        @refresh="refreshStop('siparis-yonetimi')"
    >
      <b-row class="mt-50 mb-5">
        <div class="card-header-row"
             :style="{backgroundColor: !adisyon.arsiv ? $store.state.colors.headerRowColor: '#5C8374'}"
        >
          <b-col cols="12">
            <h4 v-if="!adisyon.arsiv" class="text-white d-inline-block card-title">Sipariş Yönetimi ( {{ masaAdi }}
              )</h4>
            <h4 v-else class="text-white d-inline-block card-title">Geçmiş Sipariş Detayı ( {{ masaAdi }} )</h4>
          </b-col>
        </div>
      </b-row>
      <b-row :key="count">
        <b-col cols="12">
          <div class="small-screen-row">
            <b-dropdown
                v-if="isAdisyon && !adisyon.arsiv"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Adisyon İşlemleri"
                variant="primary"
                class="float-right mr-50"
            >
              <b-dropdown-item v-if="$store.state.role === 'pc'" variant="info" v-b-modal.payment-modal
                               @click="isPayment = true"
              >
                <font-awesome-icon icon="credit-card" class="mr-50"/>
                Öde
              </b-dropdown-item>
              <b-dropdown-item v-if="$store.state.role === 'pc'" v-b-modal.fast-payment-modal variant="success"
                               @click="isFastPayment = true"
              >
                <font-awesome-icon icon="credit-card" class="mr-50"/>
                Hızlı Öde
              </b-dropdown-item>
              <b-dropdown-item variant="secondary" @click="orderClosed()" :disabled="!odemeDurum">
                <font-awesome-icon icon="xmark" class="mr-50"/>
                Kapat
              </b-dropdown-item>
              <b-dropdown-item variant="danger" @click="adisyonSil()">
                <font-awesome-icon icon="trash" class="mr-50"/>
                Adisyonu İptal Et
              </b-dropdown-item>
            </b-dropdown>
            <b-button
                v-if="adisyon.arsiv"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="float-right mr-1"
                disabled
            >
              <font-awesome-icon v-if="adisyonNotu" icon="archive" class="mr-25"/>
              Arşiv
            </b-button>
            <b-button
                v-if="isAdisyon"
                v-b-modal.adisyon-notu
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                class="float-right mr-1"
            >
              <font-awesome-icon v-if="adisyonNotu" icon="star" class="mr-25"/>
              Not
            </b-button>
          </div>
        </b-col>
        <b-col lg="5" md="12" sm="12" cols="12">
          <h4 class="d-inline-block mt-1">Siparişler</h4>
          <div class="big-screen-row">
            <b-dropdown
                v-if="isAdisyon && !adisyon.arsiv"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Adisyon İşlemleri"
                variant="primary"
                class="float-right mr-50"
            >
              <b-dropdown-item v-if="$store.state.role === 'pc'" variant="success" v-b-modal.payment-modal
                               @click="isPayment = true"
              >
                <font-awesome-icon icon="credit-card" class="mr-50"/>
                Öde
              </b-dropdown-item>
              <b-dropdown-item v-if="$store.state.role === 'pc'" v-b-modal.fast-payment-modal variant="success"
                               @click="isFastPayment = true"
              >
                <font-awesome-icon icon="credit-card" class="mr-50"/>
                Hızlı Öde
              </b-dropdown-item>
              <b-dropdown-item variant="secondary" @click="orderClosed()" :disabled="!odemeDurum">
                <font-awesome-icon icon="xmark" class="mr-50"/>
                Kapat
              </b-dropdown-item>
              <b-dropdown-item variant="danger" @click="adisyonSil()">
                <font-awesome-icon icon="trash" class="mr-50"/>
                Adisyonu İptal Et
              </b-dropdown-item>
            </b-dropdown>
            <b-button
                v-if="adisyon.arsiv"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="float-right mr-1"
                disabled
            >
              <font-awesome-icon v-if="adisyonNotu" icon="archive" class="mr-25"/>
              Arşiv
            </b-button>
            <b-button
                v-if="isAdisyon"
                v-b-modal.adisyon-notu
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="float-right mr-1 bg-success bg-darken-1 border-0"
            >
              <font-awesome-icon v-if="adisyonNotu" icon="star" class="mr-25"/>
              Not
            </b-button>
          </div>
          <hr>
          <b-modal
              id="adisyon-notu"
              title="Adisyon Notu"
              centered
              no-fade
              ok-title="Güncelle"
              cancel-title="Kapat"
              ok-variant="success"
              @ok="updateOrderNote()"
              @hide="adisyonDetay()"
              :ok-disabled="adisyon.arsiv"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                    :label="!adisyon.arsiv ? 'Not Giriniz':'Adisyon Notu'"
                    label-for="basicInput"
                >
                  <b-form-textarea
                      id="basicInput"
                      v-model="adisyonNotu"
                      :disabled="adisyon.arsiv"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-modal>
          <b-list-group v-if="isAdisyon" flush class="mt-2 siparis-list">
            <b-list-group-item v-for="order in adisyon.urunList" :key="order.detayID"
                               v-b-modal.update-order
                               v-ripple.400="'rgb(166, 209, 230,0.15)'"
                               @click="updateAtama(order)"
            >
              <b-row class="mr-25">
                <b-col lg="8" md="8" sm="8" cols="12" class="item-col-area">
                  <div class="item-count">
                    <b>{{ order.adet }}</b>
                  </div>
                  <div class="item-detail">
                    <h5 class="m-0">{{ order.stokAdi }}</h5>
                  </div>
                  <div class="item-detail urun-note">
                    <b-badge v-if="order.birimNotu" variant="light-success" class="d-block ml-1 birim-note">
                      <font-awesome-icon icon="star" class="mr-25"/>
                      <h6 class="d-inline-block m-0 font-weight-bolder">{{ order.birimNotu }}</h6>
                    </b-badge>
                  </div>
                </b-col>
                <b-col lg="4" md="4" sm="4" cols="12" class="item-col-area p-0">
                  <div class="item-price d-block mr-1">
                    <small>{{ order.adet }}x {{
                        Number(order.tutar)
                      }}
                      <font-awesome-icon icon="turkish-lira-sign"/>
                    </small>
                    <h6 class="font-weight-bolder font-medium-1">{{
                        Number(order.toplamTutar)
                      }}
                      <font-awesome-icon icon="turkish-lira-sign"/>
                    </h6>
                  </div>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <b-card v-if="isAdisyon" class="finans-card">
            <b-list-group flush>
              <b-list-group-item class="justify-content-between d-flex">
                <strong>Toplam Tutar :</strong>
                <strong>{{
                    Number(adisyon.finans.toplamTutar)
                  }}
                  <font-awesome-icon icon="turkish-lira-sign"/>
                </strong></b-list-group-item>
              <b-list-group-item class="justify-content-between d-flex"><strong>Tahsil Edilen :</strong>
                <strong>{{
                    Number(adisyon.finans.toplamTahsilat)
                  }}
                  <font-awesome-icon icon="turkish-lira-sign"/>
                </strong></b-list-group-item>
              <b-list-group-item class="justify-content-between d-flex"><strong>Kalan Borç :</strong>
                <strong>{{
                    Number(adisyon.finans.kalanBorc)
                  }}
                  <font-awesome-icon icon="turkish-lira-sign"/>
                </strong></b-list-group-item>
            </b-list-group>
            <hr>
            <b-row>
              <b-col cols="12">
                <small v-if="isAdisyon">Sipariş Zamanı : {{ sure }}</small>
              </b-col>
              <b-col v-if="!adisyon.arsiv" cols="12" class="d-flex justify-content-end">
                <b-button v-if="$store.state.role === 'pc'" v-b-modal.payment-modal variant="relief-info"
                          class="mr-50 ode-btn-color" @click="isPayment = true"
                >
                  <font-awesome-icon icon="credit-card" class="mr-50"/>
                  Öde
                </b-button>
                <ode v-if="isPayment" :order-data="adisyon" :adisyonID="$route.params.adisyonID"
                     :masa-adi="masaAdi"
                     @finished="paymentFinished"
                />
                <hizli-ode v-if="isFastPayment" :order-data="adisyon" :adisyonID="$route.params.adisyonID"
                           :masa-adi="masaAdi" @finished="paymentFinished"
                />
                <b-button v-if="$store.state.role === 'pc'" v-b-modal.fast-payment-modal variant="relief-success"
                          @click="isFastPayment = true"
                >
                  <font-awesome-icon icon="bolt" class="mr-50"/>
                  Hızlı Öde
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col v-if="!adisyon.arsiv" lg="7" md="12" sm="12" cols="12">
          <b-row class="grup-list">
            <b-card v-for="item in grupListesi" :key="item.stokGrupID"
                    v-ripple.400="'rgb(152, 238, 204,0.15)'"
                    class="grup-card-item"
                    :class="activeGrupID === item.stokGrupID ? 'active-grup':''"
                    @click="activeGrupID = item.stokGrupID"
            >
              <b-card-body class="text-center p-0 d-flex justify-content-center">
                <h5 class="group-name d-inline-block mr-50">
                  {{ item.grupAdi }}</h5>
              </b-card-body>
            </b-card>
          </b-row>
          <b-row class="menu-list">
            <b-col v-for="item in menuListesi" :key="item.stokID">
              <b-card v-ripple.400="'rgb(58, 176, 255,0.15)'" v-b-modal.add-order class="item"
                      @click="waitOrder = {...item,adet: 1,urunNotu : null}"
              >
                <h5>{{ item.stokAdi }}</h5>
                <small class="font-weight-bolder">
                  {{
                    Number(item.tutar)
                  }}
                  <font-awesome-icon icon="turkish-lira-sign"/>
                </small>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-alert
        v-else
        variant="danger"
        show
    >
      <div class="alert-body">
        <strong>Masa Bulunamadı.</strong>
      </div>
    </b-alert>
    <b-modal
        id="add-order"
        :title="`Ürün Ekleme (${waitOrder.stokAdi})`"
        hide-footer
        no-fade
        centered
        @hide="waitOrder = []"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Ürün İsmi"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="waitOrder.stokAdi"
                autocomplete="off"
                disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Adet Başı Tutar"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-numericOnly
                v-model="waitOrder.tutar"
                type="number"
                autocomplete="off"
                :step="priceStep"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Adet"
              label-for="basicInput"
          >
            <b-form-spinbutton
                id="demo-sb"
                v-model="waitOrder.adet"
                min="1"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Ürün Notu"
              label-for="basicInput"
          >
            <b-form-textarea
                id="textarea-default"
                v-model="waitOrder.urunNotu"
                autocomplete="off"
                rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-50"
              @click="$bvModal.hide('add-order'), waitOrder = []"
          >
            Kapat
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="addOrder()"
          >
            <font-awesome-icon icon="floppy-disk" class="mr-50"/>
            Ekle
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="update-order"
        :title="`Ürün Detayı (${selectedOrder.stokAdi})`"
        hide-footer
        centered
        no-fade
        @hide="selectedOrder = []"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Ürün İsmi"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="selectedOrder.stokAdi"
                autocomplete="off"
                disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Adet Başı Tutar"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="selectedOrder.tutar"
                v-numericOnly
                :disabled="adisyon.arsiv"
                type="number"
                autocomplete="off"
                :step="priceStep"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Adet"
              label-for="basicInput"
          >
            <b-form-spinbutton
                id="demo-sb"
                v-model="selectedOrder.adet"
                :disabled="adisyon.arsiv"
                min="1"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Ürün Notu"
              label-for="basicInput"
          >
            <b-form-textarea
                id="textarea-default"
                v-model="selectedOrder.urunNotu"
                :disabled="adisyon.arsiv"
                autocomplete="off"
                rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
              v-if="!adisyon.arsiv"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mr-50"
              @click="urunSil(selectedOrder.detayID)"
          >
            Ürünü Sil
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="mr-50"
              @click="$bvModal.hide('update-order'), selectedOrder = []"
          >
            Kapat
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="adisyon.arsiv"
              variant="success"
              @click="updateOrder()"
          >
            <font-awesome-icon icon="floppy-disk" class="mr-50"/>
            Güncelle
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  VBToggle,
  BCollapse,
  BCardFooter,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  VBModal,
  BFormSpinbutton,
  BFormTextarea,
  BAlert,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
  BContainer, BCardHeader, BMedia, BAvatar, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import store from '@/store'
import axios from 'axios'
import router from '@/router'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ode from '@/views/siparis/odeme/ode.vue'
import HizliOde from '@/views/siparis/odeme/hizliOde.vue'

export default {
  components: {
    HizliOde,
    BLink,
    BAvatar,
    BMedia,
    Ode,
    BCollapse,
    AppCollapse,
    AppCollapseItem,
    BCardFooter,
    BCardHeader,
    BCard,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    BFormSpinbutton,
    BAlert,
    BModal,
    BCardActions,
    BTabs,
    BTab,
    BButton,
    BRow,
    BCol,
    BModal,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BCardText,
    BFormCheckbox,
    BFormRadio,
    BSpinner,
    VBTooltip,
    BBadge,
    BContainer,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      sure: null,
      isPayment: false,
      isFastPayment: false,
      count: 0,
      isAdisyon: null,
      activeGrupID: null,
      adisyonNotu: null,
      masaID: this.$route.params.masaID,
      adisyonID: this.$route.params.adisyonID,
      waitOrder: [],
      selectedOrder: [],
    }
  },
  computed: {
    odemeDurum: () => store.getters.GET_ADISYON_DETAY.finans?.odemeDurum === '1',
    masaAdi() {
      const foundMasa = Object.values(store.getters.GET_DASH_DATA)
          .flatMap(salon => (salon.masaListesi && Object.values(salon.masaListesi)
              .find(masa => masa.masaID === this.masaID)
              ? [Object.values(salon.masaListesi)
                  .find(masa => masa.masaID === this.masaID)]
              : []))
      return foundMasa.length > 0 ? foundMasa[0].masaAdi : ''
    },
    adisyon() {
      return {
        arsiv: store.getters.GET_ADISYON_DETAY.adisyonDurum === '0',
        finans: store.getters.GET_ADISYON_DETAY.finans,
        urunList: store.getters.GET_ADISYON_DETAY.urunListesi,
      }
    },
    menuListesi: () => store.getters.GET_MENU_LIST,
    grupListesi: () => store.getters.GET_MENU_GRUP_LIST,
  },
  watch: {
    activeGrupID(newVal) {
      this.grupChange(newVal)
    },
  },
  beforeDestroy() {
    store.commit({
      type: 'SET_ADISYON_DETAY',
      action: 0,
    })
    store.commit({
      type: 'SET_MENU_GRUP_LIST',
      action: 0,
    })
    this.isAdisyon = null
  },
  created() {
    if (this.$route.params.adisyonID) {
      this.adisyonDetay()
    }
    this.grupListPost()
  },
  methods: {
    orderClosed() {
      const fd = this.postSchema()
      fd.set('serviceName', 'adisyon')
      fd.append('methodName', 'adisyonKapat')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              router.push('/')
            }
          })
    },
    adisyonSil() {
      this.$swal({
        title: 'Adisyon İptal Edilecek',
        text: 'Bu işlem geri alınamaz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              const fd = this.postSchema()
              fd.set('serviceName', 'adisyon')
              fd.append('methodName', 'adisyonSil')
              axios.post('', fd)
                  .then(response => {
                    if (response.result.status === 200) {
                      router.push('/')
                    }
                  })
            }
          })
    },
    paymentFinished(status, noClosed) {
      if (noClosed === 1) {
        this.isPayment = !this.isPayment
        this.isFastPayment = !this.isFastPayment
      }
      this.adisyonDetay()
    },
    updateAtama(order) {
      this.selectedOrder = {
        adet: parseInt(order.adet),
        detayID: order.detayID,
        stokAdi: order.stokAdi,
        stokGrupID: order.stokGrupID,
        stokID: order.stokID,
        toplamTutar: order.toplamTutar,
        tutar: order.tutar,
        urunNotu: order.birimNotu,
      }
    },
    urunSil(detayID) {
      const fd = this.postSchema()
      fd.set('serviceName', 'adisyon')
      fd.append('methodName', 'adisyonBirimSil')
      fd.append('detayID', detayID)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              if (response.adisyonData.urunListesi.length !== 0) {
                const { adisyonID } = this.$route.params
                if (adisyonID !== response.adisyonData.adisyonID) {
                  router.push({
                    name: 'order',
                    params: {
                      masaID: this.masaID,
                      adisyonID: response.adisyonData.adisyonID,
                    },
                  })
                }
                this.adisyonID = response.adisyonData.adisyonID || 0
                response.adisyonData.urunListesi.length !== 0 ? this.isAdisyon = true : this.isAdisyon = false
                store.commit({
                  type: 'SET_ADISYON_DETAY',
                  action: 1,
                  list: response.adisyonData,
                })
                this.count++
              } else {
                router.push('/')
              }
            }
          })
    },
    updateOrderNote() {
      const fd = this.postSchema()
      fd.set('serviceName', 'adisyon')
      fd.append('methodName', 'adisyonNotDuzenle')
      fd.append('not', this.adisyonNotu || '')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.adisyonDetay()
              this.$bvModal.hide('adisyon-notu')
            }
          })
    },
    updateOrder() {
      const fd = this.postSchema()
      fd.set('serviceName', 'adisyon')
      fd.append('methodName', 'adisyonBirimDuzenle')
      fd.append('detayID', this.selectedOrder.detayID)
      fd.append('adet', this.selectedOrder.adet || 1)
      fd.append('tutar', this.selectedOrder.tutar.replace(/,/g, '.') || 0)
      fd.append('birimNotu', this.selectedOrder.urunNotu || '')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              const { adisyonID } = this.$route.params
              if (adisyonID !== response.adisyonData.adisyonID) {
                router.push({
                  name: 'order',
                  params: {
                    masaID: this.masaID,
                    adisyonID: response.adisyonData.adisyonID,
                  },
                })
              }
              this.adisyonID = response.adisyonData.adisyonID
              this.$bvModal.hide('update-order')
              response.adisyonData.urunListesi.length !== 0 ? this.isAdisyon = true : this.isAdisyon = false
              store.commit({
                type: 'SET_ADISYON_DETAY',
                action: 1,
                list: response.adisyonData,
              })
              this.count++
            }
          })
    },
    addOrder() {
      const fd = this.postSchema()
      fd.append('methodName', 'adisyonBirimKayit')
      fd.set('serviceName', 'adisyon')
      fd.append('adisyon[0][tutar]', this.waitOrder.tutar.replace(/,/g, '.') || 0)
      fd.append('adisyon[0][adet]', this.waitOrder.adet || 1)
      fd.append('adisyon[0][stokID]', this.waitOrder.stokID)
      fd.append('adisyon[0][birimNotu]', this.waitOrder.urunNotu || '')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              const { adisyonID } = this.$route.params
              if (adisyonID !== response.adisyonData.adisyonID) {
                router.push({
                  name: 'order',
                  params: {
                    masaID: this.masaID,
                    adisyonID: response.adisyonData.adisyonID,
                  },
                })
              }
              this.adisyonID = response.adisyonData.adisyonID
              this.$bvModal.hide('add-order')
              response.adisyonData.urunListesi.length !== 0 ? this.isAdisyon = true : this.isAdisyon = false
              store.commit({
                type: 'SET_ADISYON_DETAY',
                action: 1,
                list: response.adisyonData,
              })
              this.count++
            }
          })
    },
    grupChange(grupID = this.grupListesi[0]?.stokGrupID) {
      const fd = this.postSchema()
      fd.append('methodName', 'stokListesi')
      fd.append('stokGrupID', grupID)
      if (grupID !== undefined) {
        axios.post('', fd)
            .then(response => store.commit({
              type: 'SET_MENU_LIST',
              action: 1,
              list: response.stokList,
            }))
      }
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'stok')
      fd.append('adisyonID', this.adisyonID !== 0 ? this.adisyonID : '')
      fd.append('masaID', this.masaID)
      return fd
    },
    grupListPost() {
      const fd = this.postSchema()
      fd.append('methodName', 'stokGrupListesi')
      axios.post('', fd)
          .then(response => {
            if (response.stokGrupList) {
              this.activeGrupID = response.stokGrupList[0]?.stokGrupID
            }
            store.commit({
              type: 'SET_MENU_GRUP_LIST',
              action: 1,
              list: response.stokGrupList,
            })
          })
    },
    adisyonDetay() {
      const fd = this.postSchema()
      fd.set('serviceName', 'adisyon')
      fd.append('methodName', 'adisyonDetay')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.isAdisyon = true
              this.adisyonNotu = response.adisyonData.not
              this.sure = response.adisyonData.sure
              store.commit({
                type: 'SET_ADISYON_DETAY',
                action: 1,
                list: response.adisyonData,
              })
            } else {
              this.isAdisyon = false
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>
<style>
.siparis-yonetimi .card-header {
  display: none;
}

.siparis-yonetimi .card .card-title {
  padding: 0;
  margin: 7px;
}
</style>
<style scoped>
.tab-1 h4:before {
  content: '';
  top: 48px;
  border-bottom: 2px solid #92B4EC;
  width: 10%;
  position: absolute;
  transition: 1000ms;
}

.siparis-list {
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.item-count {
  padding: 5px;
  background-color: #DBDFEA;
  width: 25px;
  height: 25px;
  place-items: center;
  display: inline-block;
  margin-right: 5%;
  white-space: nowrap;
}

.item-count b {
  top: -2px;
  left: 3px;
  position: relative;
}

.item-detail {
  display: inline-block;
}

.item-price {
  display: flex;
  justify-content: end;
}

.item-price h6 {
  margin: 0;
}

.item-col-area {
  display: flex;
  place-items: center;
}

.item-col-area:nth-of-type(2) {
  justify-content: end;
}

.grup-card-item {
  cursor: pointer;
  margin: 0.2rem;
  width: 135px;
}

.grup-card-item .card-body {
  padding: 1rem;
  color: white;
  background-color: #ECF8F9;
  white-space: nowrap;
}

.grup-card-item h5 {
  margin: 0;
  font-size: 14px;
}

.grup-card-item:after {
  content: '';
  position: relative;
  border-bottom: 3px solid #0B666A;
}

.active-grup:after {
  border-bottom: 3px solid #ce4760 !important;
}

.grup-list .col {
  padding: 0;
  border-right: 1px solid #9DB2BF;
  height: 100%;
}

.menu-list {
  padding: 0;
  float: left;
}

.menu-list .item {
  cursor: pointer;
  background-color: #FFFFFF;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  width: 130px;
  min-height: 80px;
}

.menu-list .item h5 {
  font-weight: 900;
  font-family: cursive;
  margin: 0;
}

.menu-list .card {
  border-radius: 0;
  margin-bottom: 0;
}

.menu-list .card-body {
  padding: 0.4rem;
  min-height: 60px;
}

.order-dropdown {
  background-color: #f4f6f7;
}

.finans-card .card-body {
  margin-top: 8%;
  padding: 0.5rem;
  border: 4px solid #85d5db;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.badge {
  padding: 0.2rem 0.4rem;
}

.group-name {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.urun-note {
  height: 100%;
  vertical-align: bottom;
  align-items: end;
  display: flex;
}

.birim-note {
  place-items: center;
  display: flex !important;
}

.menu-list .col {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.small-screen-row {
  display: none;
}

.big-screen-row {
  display: contents;
}

@media screen and (max-width: 500px) {
  .big-screen-row {
    display: none;
  }

  .small-screen-row {
    display: inline;
  }
}

.ode-btn-color {
  background-color: #0dab76;
}
</style>
