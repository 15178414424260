<template>
  <div>
    <b-modal
        id="fast-payment-modal"
        centered
        no-fade
        hide-footer
        size="lg"
        :title="`Masa Adı : ${masaAdi}`"
    >
      <b-row>
        <b-col cols="12" class="text-center">
          <h4>
            <strong> Kalan Tutar :
              <b-badge variant="light-primary">
                {{
                  Number(orderData.finans.kalanBorc)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }}
                <font-awesome-icon icon="turkish-lira-sign"/>
              </b-badge>
            </strong>
          </h4>
        </b-col>
        <b-col lg="12" md="12" sm="12" cols="12">
          <hr>
          <b-row class="odeme-tip-row mt-lg-0 mt-md-2 mt-sm-2 mt-2">
            <b-col lg="3" md="4" sm="6" cols="6" class="p-25">
              <b-card v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :class="odemeTipSelected === 'nakit' ? 'tip-selected':''"
                      @click="odemeTipSelected = 'nakit'"
              >
                <div class="odeme-tipi">
                  <font-awesome-icon icon="money-bills" class="fa-2xl"/>
                  <h5 class="mt-50">Nakit</h5>
                </div>
              </b-card>
            </b-col>
            <b-col lg="3" md="4" sm="6" cols="6" class="p-25">
              <b-card v-ripple.400="'rgba(113, 102, 240, 0.15)'" :class="odemeTipSelected === 'kredi'?'tip-selected':''"
                      @click="odemeTipSelected = 'kredi'"
              >
                <div class="odeme-tipi">
                  <font-awesome-icon icon="credit-card" class="fa-2xl"/>
                  <h5 class="mt-50">Kredi Kartı</h5>
                </div>
              </b-card>
            </b-col>
            <b-col lg="3" md="4" sm="6" cols="6" class="p-25">
              <b-card v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :class="odemeTipSelected === 'havale'?'tip-selected':''"
                      @click="odemeTipSelected = 'havale'"
              >
                <div class="odeme-tipi">
                  <font-awesome-icon icon="money-bill-transfer" class="fa-2xl"/>
                  <h5 class="mt-50">Havale</h5>
                </div>
              </b-card>
            </b-col>
            <b-col lg="3" md="4" sm="6" cols="6" class="p-25">
              <b-card v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :class="odemeTipSelected === 'acik-hesap'?'tip-selected':''"
                      @click="odemeTipSelected = 'acik-hesap' , $bvModal.show('fast-cari-list') , cariSearch()"
              >
                <div class="odeme-tipi">
                  <font-awesome-icon icon="users" class="fa-2xl"/>
                  <h5 class="mt-50">Açık Hesap</h5>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="odemeTipSelected === 'acik-hesap' && cariKart" class="mt-2">
            <b-col cols="12">
              <b-list-group>
                <b-list-group-item class="musteri-list">
                  <strong>Müşteri Adı : {{
                      musteriAdi.length !== 1 ? musteriAdi : `${cariKart.adi} ${cariKart.soyAdi}`
                    }}</strong>
                  <b-button
                      variant="gradient-danger"
                      class="btn-icon rounded-circle"
                      @click="cariKart = null"
                  >
                    <font-awesome-icon icon="trash"/>
                  </b-button>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <b-button :disabled="btnDisabled()" variant="relief-success" class="mr-50" @click="odemeSave(1)">
                <font-awesome-icon icon="floppy-disk" class="mr-50"/>
                Öde
              </b-button>
              <b-button :disabled="btnDisabled()" variant="relief-info" @click="odemeSave(2)">
                <font-awesome-icon icon="floppy-disk" class="mr-50"/>
                Öde ve Kapat
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="fast-cari-list"
        ok-title="Kaydet"
        cancel-title="Kapat"
        centered
        no-fade
        no-close-on-backdrop
        title="Cari Seçimi"
        modal-class="modal-warning"
    >
      <v-select
          v-model="cariKart"
          :options="cariList"
          :get-option-label="option => option.ad + ' ' + option.soyad + ' - ' + option.faturaUnvan"
          input-id="invoice-data-client"
          :clearable="false"
          @search="(search, loading) => { loading(true)
                         cariSearch(search).then(() => loading(false))
                        }"
      >
        <template #no-options>
          <p>Müşteri Bulunamadı</p>
        </template>
        <template #selected-option-container="{ option }">
          <div class="vs__selected">
            {{
              getCariOptionFormat(option.faturaUnvan, option.adi, option.soyAdi).length !== 1 ? getCariOptionFormat(option.faturaUnvan, option.adi, option.soyAdi) : `${option.adi} ${option.soyAdi}`
            }}
          </div>
        </template>
        <template #option="{ faturaUnvan, adi,soyAdi}">
          {{
            getCariOptionFormat(faturaUnvan, adi, soyAdi).length !== 1 ? getCariOptionFormat(faturaUnvan, adi, soyAdi) : `${adi} ${soyAdi}`
          }}
        </template>
        <template #list-header>
          <li
              v-b-modal.fast-new-cari
              class="add-new-client-header d-flex align-items-center my-50"
          >
            <feather-icon
                icon="PlusIcon"
                size="16"
            />
            <span class="align-middle ml-25">Yeni Cari Kart</span>
          </li>
        </template>
      </v-select>
    </b-modal>
    <b-modal
        id="fast-new-cari"
        ok-title="Kaydet"
        cancel-title="Kapat"
        centered
        no-fade
        hide-footer
        size="xl"
        title="Yeni Cari"
    >
      <b-row class="px-3 mt-1">
        <b-col cols="12">
          <cari-save @saving="cariSave"/>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BFormTextarea,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  VBModal,
  BvModal,
  VBTooltip,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import axios from 'axios'
import CariSave from '@/views/cari/cariSave.vue'
import router from '@/router'

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  components: {
    BInputGroupAppend,
    BBadge,
    BFormTextarea,
    CariSave,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  props: ['orderData', 'adisyonID', 'masaAdi'],
  data() {
    return {
      tutar: null,
      cariKart: null,
      odemeTipSelected: 'nakit',
    }
  },
  computed: {
    tahsilatTuru() {
      const tip = this.odemeTipSelected
      return tip === 'nakit' ? 1 : tip === 'havale' ? 2 : tip === 'kredi' ? 3 : tip === 'acik-hesap' ? 4 : 1
    },
    musteriAdi() {
      return this.cariKart.faturaUnvan ? this.cariKart.faturaUnvan.toUpperCase() : `${this.cariKart.adi} ${this.cariKart.soyAdi}`
    },
    cariList: () => store.getters.GET_CARI_LIST,
  },
  created() {
    this.tutar = this.orderData.finans.kalanBorc
    if (this.adisyonID) {
      this.$bvModal.show('fast-payment-modal')
    }
  },
  methods: {
    btnDisabled() {
      if (this.odemeTipSelected === 'acik-hesap' && (this.cariKart === null || this.cariKart === '')) {
        return true
      }
      return false
    },
    odemeSave(a) {
      const fd = this.postSchema()
      fd.append('methodName', 'tahsilatKayit')
      fd.append('tutar', String(this.tutar)
          .replace(/,/g, '.') || 0)
      fd.append('tahsilatTuru', this.tahsilatTuru || 1)
      fd.append('aciklama', '')
      fd.append('fisNo', '')
      if (this.odemeTipSelected === 'acik-hesap') {
        fd.append('cariKartID', this.cariKart.cariKartID || '')
      }
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              if (a === 1) {
                this.$emit('finished', 1, 1)
              } else if (a === 2) {
                this.orderClosed()
              }
            }
          })
    },
    orderClosed() {
      const fd = this.postSchema()
      fd.set('serviceName', 'adisyon')
      fd.append('methodName', 'adisyonKapat')
      fd.append('adisyonID', this.adisyonID)
      axios.post('', fd)
          .then(response => (response.result.status === 200 && this.$route.name !== 'dashBoard' ? router.push('/') : this.$emit('finished', 1, 1)))
    },
    getCariOptionFormat(faturaUnvan, adi, soyAdi) {
      if (faturaUnvan !== '') {
        return faturaUnvan.toUpperCase()
      } else {
        return `${adi} ${soyAdi}`
      }
    },
    cariSave(value, cari) {
      this.cariKart = cari
      this.$bvModal.hide('fast-new-cari')
    },
    cariSearch(searchKey) {
      return new Promise((resolve, reject) => {
        const fd = this.postSchema()
        fd.set('serviceName', 'cari')
        fd.append('methodName', 'cariListeSearch')
        fd.append('searchKey', searchKey || '')
        fd.append('baslangic', 0)
        fd.append('uzunluk', 20)
        axios.post('', fd)
            .then(response => {
              resolve(response.cariList)
              store.commit({
                type: 'SET_CARI_LIST',
                list: response.cariList,
                action: 1,
              })
            })
            .catch(() => reject(false))
      })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'adisyon')
      fd.append('adisyonID', this.adisyonID !== 0 ? this.adisyonID : '')
      return fd
    },
  },
}
</script>

<style scoped>

.odeme-tipi {
  display: grid;
  place-items: center;
}

.tip-selected {
  border: 2px solid #4CACBC;
}

.odeme-tip-row .card {
  margin: 0;
}

.odeme-tipi .card-body {
  padding: 0.8rem;
}

.odeme-tip-row .card-body {
  padding: 20px 0 5px 0;
}

.musteri-list {
  border: 1px solid #6fbb83 !important;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
</style>
